import { AuthController } from '@controllers/auth-controller';
import { CAMPAIGN_MANAGEMENT } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class CampaignManagementController extends AuthController {
  public pageId = CAMPAIGN_MANAGEMENT;
  public path = '/campaigns';
  public routeName = 'campaign-management';
  public bundles = [];
  public pageTitle = CAMPAIGN_MANAGEMENT;

  public component = codeSplit(
    () => import(/* webpackChunkName: "campaign-management" */ '../views/campaign-management'),
  );
  public exact = true;
}
