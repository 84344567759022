import { AuthController } from '@controllers/auth-controller';
import { USERS } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class UsersController extends AuthController {
  public pageId = USERS;
  public path = '/users';
  public routeName = USERS;
  public bundles = [];
  public pageTitle = USERS;
  public component = codeSplit(() => import(/* webpackChunkName: "users" */ '../views/users'));
  public exact = true;
}
