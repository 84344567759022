import { getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { serializeError } from 'serialize-error';
import { JSON_HEADERS, TRACE_ID } from 'src/constants';

const SERVICE_NAME = 'PARTNER_SERVICE';

export interface IPartnerDetails {
  account_id: string;
  name: string;
  number: string;
  status: string;
  partner_id: string;
  created_date: string;
  created_user: string;
  updated_date: string;
  updated_user: string;
  is_sandbox_account: boolean;
}

export interface IUserPartners {
  page_size: number;
  next_page_token: string;
  user_accounts: IUserPartner[];
}

interface IUserPartner {
  account_id: string;
  account_name: string;
  partner_id: string;
  partner_name: string;
}

const getPartnerAccount: (
  request,
  principalToken: string,
  partnerAccountId: string,
) => Promise<IPartnerDetails & Error> = async (request, principalToken, partnerAccountId) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getPartnerAccount', traceId], {
    partnerAccountId: partnerAccountId,
  });

  if (!principalToken || !partnerAccountId) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.getPartnerAccount', traceId], {
      partnerAccountId: partnerAccountId,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const service = getConfig()['services']['partner-service'];
  const { protocol, hostname } = service;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}/v1/accounts/${partnerAccountId}`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${principalToken}`,
      },
    });

    return response.data;
  } catch (error) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.getPartnerAccount', traceId], {
      partnerAccountId: partnerAccountId,
      error: serializeError(error),
    });
    return {};
  }
};

export { getPartnerAccount };
