import { getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { serializeError } from 'serialize-error';
import { JSON_HEADERS, TRACE_ID } from 'src/constants';

const SERVICE_NAME = 'USER_PROFILE_SERVICE';

export interface IUserProfile {
  userId: string;
  status: string;
  data: IProfileData;
  metadata: {
    id: string;
    revision: number;
    createdBy: string;
    createdTimestamp: string;
    lastModifiedBy: string;
    lastModifiedTimestamp: string;
  };
}

interface IProfileData {
  'v1/account-holder-info': {
    shared: {
      basic_info: {
        first_name: string;
        last_name: string;
      };
      contact_info: {
        primary_email: {
          email: string;
        };
      };
    };
  };
}

const getUserProfile: (request, principalToken, userId) => Promise<IUserProfile & Error> = async (
  request,
  principalToken,
  userId,
) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getUserProfile', traceId], { userId });

  if (!principalToken || !userId) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.getUserProfile', traceId], {
      userId: userId,
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const service = getConfig()['services']['universal-profile-service'];
  const { protocol, hostname } = service;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}/v1/profile/${userId}`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${principalToken}`,
      },
    });

    return response.data || {};
  } catch (error) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.getUserProfile', traceId], {
      error: serializeError(error),
    });
    return null;
  }
};

export { getUserProfile };
