import { Request as HapiRequest } from '@hapi/hapi';
import { getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { Request as BernieRequest } from 'bernie-http';
import { serializeError } from 'serialize-error';
import { JSON_HEADERS, TRACE_ID } from 'src/constants';

const SERVICE_NAME = 'IDENTITY_TOKEN_SERVICE';

export interface PrincipalUserData {
  ver: string;
  sub: string;
  partner_account_id: string;
  iss: string;
  client_id: string;
  aud: string;
  acr: string[];
  nbf: number;
  idp: number;
  scope: string;
  auth_time: number;
  session_exp: number;
  actor_id: string;
  exp: number;
  iat: number;
  client_name: string;
  jti: string;
}

interface IPrincipalResponse {
  encodedJwt: string;
}

const getOpaqueToken = (request: BernieRequest | HapiRequest): string | undefined => {
  const cookies = request.headers.cookie.split('; ');
  const sessionToken = cookies?.find((row: string) => row.startsWith('EG_SESSIONTOKEN='));
  return sessionToken ? sessionToken.split('=')[1] : sessionToken;
};

const getPrincipalToken: (request) => Promise<IPrincipalResponse & Error> = async (request) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.getPrincipalToken', traceId]);

  const opaqueToken = getOpaqueToken(request);
  if (!opaqueToken) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.getPrincipalToken', traceId], {
      message: 'no opaque token',
    });
    throw new Error('Unauthorized');
  }

  const service = getConfig()['services']['identity-token-service'];
  const { protocol, hostname } = service;

  try {
    const response = await axios({
      method: 'POST',
      url: `${protocol}//${hostname}/v3/tokens/exchange`,
      signal: AbortSignal.timeout(5000),
      data: {
        opaqueToken,
      },
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
      },
    });

    return response.data;
  } catch (error) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.getPrincipalToken', traceId], {
      error: serializeError(error),
    });
    throw error;
  }
};

const getJwtFromToken: (token: string) => PrincipalUserData = (token) => {
  const principalUser = Buffer.from(token.split('.')[1], 'base64').toString('utf-8');
  return JSON.parse(principalUser);
};

export { getPrincipalToken, getJwtFromToken };
