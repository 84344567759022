import { lookupUser } from '@services/identity-service';
import { PrincipalUserData, getJwtFromToken, getPrincipalToken } from '@services/identity-token-service';
import { IPartnerDetails, getPartnerAccount } from '@services/partner-service';
import { getUserProfile } from '@services/user-profile-service';
import { getTraceId } from '@utils/trace-utils';
import { SystemEventLevel } from 'bernie-logger';
import { serializeError } from 'serialize-error';

export interface IPartnerSelect {
  id: string;
  name: string;
}

export interface IUser {
  userJwt: PrincipalUserData;
  profile: IUserProfile;
  partner: IPartnerDetails;
}

interface IUserProfile {
  firstName: string;
  lastName: string;
  primaryEmail: string;
}

export const getUserInfo: (request) => Promise<IUser> = async (request) => {
  try {
    const principalToken = await getPrincipalToken(request);

    const principalJwt = getJwtFromToken(principalToken.encodedJwt);
    let profileData = await getUserProfile(request, principalToken.encodedJwt, principalJwt.sub);
    // It's possible users wont show up if they haven't been migrated from
    // legacy FERRIS pool to newer BEX pool
    // Not sure when this will change

    if (!profileData || !profileData.data) {
      const user = await lookupUser(request, principalToken.encodedJwt, principalJwt.sub);
      const legacyId = user?.legacy_user_ids?.FERRIS?.egaid;
      profileData = await getUserProfile(request, principalToken.encodedJwt, legacyId);
    }
    const partnerData = await getPartnerAccount(request, principalToken.encodedJwt, principalJwt.partner_account_id);
    const profile: IUserProfile = {
      firstName: profileData.data['v1/account-holder-info'].shared.basic_info.first_name,
      lastName: profileData.data['v1/account-holder-info'].shared.basic_info.last_name,
      primaryEmail: profileData.data['v1/account-holder-info'].shared.contact_info.primary_email.email,
    };

    return {
      userJwt: principalJwt,
      profile,
      partner: partnerData,
    };
  } catch (error) {
    const traceId = getTraceId(request);
    request?.log(['IDENTITY_UTILS', 'advertiser-portal-pwa.error.getUserInfo2', traceId], {
      level: SystemEventLevel.CRITICAL,
      error: serializeError(error),
    });
    throw error;
  }
};
