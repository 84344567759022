import { AuthController } from '@controllers/auth-controller';
import { REPORTING } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class ReportingController extends AuthController {
  public pageId = 'Reporting';
  public path = '/reporting';
  public routeName = REPORTING;
  public bundles = [];
  public pageTitle = REPORTING;
  public component = codeSplit(() => import(/* webpackChunkName: "reporting" */ '../views/reporting'));
  public exact = true;
}
