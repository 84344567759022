import { Request as HapiRequest } from '@hapi/hapi';
import { Request as BernieRequest } from 'bernie-http';
import { TRACE_ID } from 'src/constants';
import { v4 as uuid } from 'uuid';

export const getTraceId = (request: BernieRequest | HapiRequest): string => {
  let trace = request.headers[TRACE_ID];
  if (!trace) {
    const newTrace = uuid();
    request.headers[TRACE_ID] = newTrace;
    trace = newTrace;
  }
  return trace;
};
