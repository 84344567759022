import { getUserInfo } from '@server/identity-utils';
import { UserStore } from '@stores/user-store';
import { getTraceId } from '@utils/trace-utils';
import { CodeSplit, Controller, FetchOptions } from 'bernie-core';
import { HttpStatus, PageData } from 'bernie-http';
import { SystemEventLevel } from 'bernie-logger';
import { serializeError } from 'serialize-error';

export abstract class AuthController implements Controller {
  public abstract pageTitle: string;

  public abstract bundles: string[];

  public abstract component: CodeSplit;

  public abstract exact: boolean;

  public abstract pageId: string;

  public abstract path: string;

  public abstract routeName: string;

  public isPartnerProtected = true;

  public isAuthenticated = true;

  public isSandboxEnabled = false;

  public fetchPageData(): Promise<PageData> {
    return Promise.resolve({
      title: this.getPageTitle(),
      pageId: this.pageId,
      pageName: this.pageId,
    }) as Promise<PageData>;
  }

  public async fetch(options: FetchOptions | undefined) {
    if (typeof options === 'object' && Object.keys(options).length > 0) {
      const { stores, isServer } = options;
      if (isServer) {
        const userStore: UserStore = stores?.get<UserStore>('user');
        let userInfo = null;
        try {
          userInfo = await getUserInfo(options.request);
        } catch (error) {
          const traceId = getTraceId(options.request);
          options.request?.log(['AUTH_CONTROLLER', 'advertiser-portal-pwa.error.fetch', traceId], {
            level: SystemEventLevel.CRITICAL,
            error: serializeError(error),
          });
        }
        const storePromises: Promise<unknown>[] = [];
        if (userInfo) {
          storePromises.push(
            userStore
              .setUserInfo({ request: options.request, userInfo })
              .then(() => Promise.resolve({ status: HttpStatus.OK })),
          );
        }
        await Promise.all(storePromises);
      } else {
        document.title = this.getPageTitle();
        return Promise.resolve({ status: HttpStatus.OK });
      }
    }
    return Promise.resolve({ status: HttpStatus.OK });
  }

  private getPageTitle(): string {
    if (this.pageTitle) {
      return `${this.pageTitle} - Advertiser Portal`;
    }

    return 'Advertiser Portal';
  }
}
