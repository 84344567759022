import { getTraceId } from '@utils/trace-utils';
import axios from 'axios';
import { getConfig } from 'bernie-config';
import { serializeError } from 'serialize-error';
import { JSON_HEADERS, TRACE_ID } from 'src/constants';

const SERVICE_NAME = 'IDENTITY_SERVICE';

export interface IUserLookup {
  eg_user_id?: string;
  legacy_user_ids?: {
    FERRIS?: {
      egaid?: string;
    };
  };
}

const lookupUser: (request, principalToken: string, userId: string) => Promise<IUserLookup & Error> = async (
  request,
  principalToken,
  userId,
) => {
  const traceId = getTraceId(request);
  request.log([SERVICE_NAME, 'advertiser-portal-pwa.info.lookupUser', traceId], { userId });

  if (!principalToken) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.lookupUser', traceId], {
      message: 'No user or token available',
    });
    throw new Error('Unauthorized');
  }

  const service = getConfig()['services']['identity-service'];
  const { protocol, hostname } = service;

  try {
    const response = await axios({
      method: 'GET',
      url: `${protocol}//${hostname}/v1/identity/${userId}`,
      signal: AbortSignal.timeout(5000),
      headers: {
        ...JSON_HEADERS,
        [TRACE_ID]: traceId,
        Authorization: `EGToken Principal-JWT=${principalToken}`,
      },
    });

    return response.data;
  } catch (error) {
    request.log([SERVICE_NAME, 'advertiser-portal-pwa.error.lookupUser', traceId], {
      error: serializeError(error),
    });
    throw error;
  }
};

export { lookupUser };
