import { SystemEvent, SystemEventLevel } from '@shared-ui/logger-context';

export const CDN_URL = 'https://c.travel-assets.com/advertiser-portal-pwa/';
export const CDN_BFLDR_URL = 'https://cdn.bfldr.com/QUXOL71E/at/m9f6h9bm8x8gm327j7ngq3r/';

export const LOG_EVENTS = {
  INFO_EVENT: new SystemEvent(SystemEventLevel.INFO, 'INFO_EVENT'),
  WARNING_EVENT: new SystemEvent(SystemEventLevel.WARN, 'WARNING_EVENT'),
  ERROR_EVENT: new SystemEvent(SystemEventLevel.ERROR, 'ERROR_EVENT'),
  CRITICAL_EVENT: new SystemEvent(SystemEventLevel.CRITICAL, 'CRITICAL_EVENT'),
};

export const TRACE_ID = 'TRACE-ID';

export const JSON_HEADERS = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};
