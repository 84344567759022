import { AuthController } from '@controllers/auth-controller';
import { CAMPAIGN_WIZARD } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class CampaignWizardController extends AuthController {
  public pageId = CAMPAIGN_WIZARD;
  public path = '/campaign-wizard';
  public routeName = 'campaign-wizard';
  public bundles = [];
  public pageTitle = CAMPAIGN_WIZARD;

  public component = codeSplit(() => import(/* webpackChunkName: "campaign-wizard" */ '../views/campaign-wizard'));
  public exact = true;
}
