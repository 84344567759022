import { BillingCenterController } from '@controllers/billing-center-controller';
import { CampaignManagementController } from '@controllers/campaign-management-controller';
import { CampaignWizardController } from '@controllers/campaign-wizard-controller';
import { ErrorController } from '@controllers/error-controller';
import { LandingPageController } from '@controllers/landing-page-controller';
import { LogoutController } from '@controllers/logout-controller';
import { ReportingController } from '@controllers/reporting-controller';
import { SelectAccountController } from '@controllers/select-account-controller';
import { UsersController } from '@controllers/users-controller';

export const controllers = [
  ErrorController,
  LandingPageController,
  LogoutController,
  SelectAccountController,
  UsersController,
  CampaignWizardController,
  CampaignManagementController,
  BillingCenterController,
  ReportingController,
];
