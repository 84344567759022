import { AuthController } from '@controllers/auth-controller';
import { BILLING_CENTER } from '@utils/controller-utils';
import { codeSplit } from 'bernie-core';

export class BillingCenterController extends AuthController {
  public pageId = BILLING_CENTER;
  public path = '/billing-center';
  public routeName = 'billing-center';
  public bundles = [];
  public pageTitle = BILLING_CENTER;

  public component = codeSplit(() => import(/* webpackChunkName: "billingCenter" */ '../views/billing-center'));
  public exact = true;
}
